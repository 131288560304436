import { Formik, FormikHelpers } from 'formik';
import * as React from 'react';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { UserActions } from '../../../common/store/user/user.actions';
import { useCurrentUser } from '../../../hooks';
import { getIsAuthorized, getSelectedUser } from '../../../common/store/user/user.selectors';
import { isUtilitiesUser, IUser } from '../../../common/types/user';
import { RequestActions } from '../../../store/actions';
import { selectCopyRequest, selectRequestDetailById, selectRequestLoading } from '../../../store/selectors';
import { ALL_REQUEST_FIELDS, IRequestAclActions, IRequestFormData } from '../../../types';
import {
  REQUEST_FORM_DEFAULT_VALUES,
  requestInitialValues,
  requestValidateValues,
  userInitialValues,
} from './request-form.management';
import { RequestEditComponent } from './RequestEdit.component';
import { UnauthorizedPage } from '../../../components';
import { Visible } from '../../../common/components/layout/Visible.component';
import { formatDateToBackendDateFormat } from '../../../common/utils/date.util';
import { useRequestMetadata } from '../../../hooks';

interface IParams {
  id?: string | undefined;
}

export const RequestEditWrapperComponent: FunctionComponent = () => {
  const dispatch = useDispatch();
  const params: IParams = useParams();
  const [hide, setHide] = useState(!!params.id);
  const [initialValues, setInitialValues] = useState<IRequestFormData>(requestInitialValues());
  const [attachmentIds, setAttachmentIds] = useState<string[]>([]);
  const copyRequest = useSelector(selectCopyRequest);
  const request = useSelector(selectRequestDetailById)[params.id || -1];
  const loading = useSelector(selectRequestLoading);
  const metadata = useRequestMetadata();
  const selectedUser = useSelector(getSelectedUser);
  const currentUser: IUser | null = useCurrentUser();
  const canEditAssignedRequest = useSelector(getIsAuthorized([IRequestAclActions.editAssignedRequest]));
  const canEditRequest = useSelector(getIsAuthorized([IRequestAclActions.editRequestDraft]));
  const createRequestWithoutLimitations = currentUser?.acl.createRequestWithoutLimitations;

  useEffect(() => {
    // AS-8960 Issue: General info form filled in with the data from the previously created requestClean selected user on component initialisation
    // Remove data on component dismount
    return () => {
      dispatch(UserActions.setSelected(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedUser) {
      setInitialValues(userInitialValues(selectedUser));
      setHide(false);
    } else if (currentUser && isUtilitiesUser(currentUser)) {
      setInitialValues(userInitialValues(currentUser));
    } else {
      setInitialValues(REQUEST_FORM_DEFAULT_VALUES);
    }
  }, [currentUser, selectedUser, setInitialValues]);

  useEffect(() => {
    if (params.id && canEditAssignedRequest) {
      dispatch(RequestActions.fetch(parseInt(params.id)));
      dispatch(RequestActions.fetchEditableFields(params.id));
    } else {
      dispatch(RequestActions.setLoading(false));
      dispatch(RequestActions.setEditableFields(ALL_REQUEST_FIELDS));
    }
  }, [dispatch, params.id, canEditAssignedRequest]);

  useEffect(() => {
    if (request && canEditAssignedRequest) {
      setInitialValues(requestInitialValues(request));
      dispatch(RequestActions.setCopyRequest(null));
      setHide(false);
    }
  }, [request, setInitialValues, dispatch, canEditAssignedRequest]);

  useEffect(() => {
    // this useEffect must be after selectedUser otherwise default values override the copied ones
    if (copyRequest) {
      setInitialValues(requestInitialValues(copyRequest, true));
      dispatch(RequestActions.setCopyRequest(null));
      setHide(false);
    }
  }, [setInitialValues, copyRequest, dispatch]);

  const onAddAttachment = useCallback((value: string) => setAttachmentIds((ids) => [...ids, value]), []);

  const handleSubmit = useCallback(
    (attachmentIds: string[]) => (values: IRequestFormData, helpers: FormikHelpers<IRequestFormData>) => {
      if (!loading) {
        helpers.setSubmitting(true);

        const utilityCompanyLimitations = createRequestWithoutLimitations
          ? {}
          : { withUtilityCompanyLimitations: true };

        const valuesToSave = {
          ...values,
          ...(values.birthday && {
            birthday: formatDateToBackendDateFormat(values.birthday),
          }),
          ...utilityCompanyLimitations,
        };

        dispatch(RequestActions.save({ id: params.id, formData: valuesToSave, attachmentIds }));

        helpers.setSubmitting(false);
      }
    },
    [createRequestWithoutLimitations, dispatch, loading, params.id],
  );

  if ((!params.id && !canEditRequest) || (params.id && !canEditAssignedRequest)) {
    return <UnauthorizedPage />;
  }

  return (
    <Visible visible={!hide && !!metadata?.tenant}>
      <Formik
        initialValues={initialValues}
        validate={requestValidateValues}
        onSubmit={handleSubmit(attachmentIds)}
        enableReinitialize
      >
        {(formikProps) => (
          <RequestEditComponent
            {...formikProps}
            currentUser={currentUser}
            metadata={metadata!}
            setInitialValues={setInitialValues}
            showEditableFieldWarning={!!request?.showEditableFieldWarning}
            attachmentIds={attachmentIds}
            onAddAttachment={onAddAttachment}
          />
        )}
      </Formik>
    </Visible>
  );
};
