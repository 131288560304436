import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NavigateNext } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import { Form, FormikProps } from 'formik';
import I18n from 'i18n-js';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { COLORS } from '../../../common';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { Visible } from '../../../common/components/layout/Visible.component';
import { isUtilitiesUser, IUser } from '../../../common/types/user';
import { useGeneralStyles } from '../../../style/generalStyles';
import { IRequestAclActions, IRequestFormData, IRequestMetadata } from '../../../types';
import { DraftPanelContainer } from './DraftPanel.container';
import { AttachmentsComponent } from './Attachments.Component';
import { RequestFormAddressComponent } from './request-form-address.component';
import { RequestFormDatetimeComponent } from './request-form-datetime.component';
import { RequestFormReasonComponent } from './request-form-reason.component';
import { RequestFormUserInfoComponent } from './request-form-user-info.component';
import { SelectUserComponent } from './SelectUserComponent';
import { Auth } from '../../../components';

const useStyles = makeStyles({
  actions: { marginTop: 30 },
  card: { marginTop: '2em' },
  cardHeader: {
    background: '#ddd',
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    fontSize: '14px',
  },
  errors: {},
  icon: {
    color: COLORS.DANGER,
    width: '17px',
    marginBottom: '-6px',
  },
  warningHeader: {
    marginBottom: '15px',
  },
});

interface IProps extends FormikProps<IRequestFormData> {
  currentUser: IUser | null;
  metadata: IRequestMetadata;
  setInitialValues: (values: IRequestFormData) => void;
  showEditableFieldWarning: boolean;
  attachmentIds: string[];
  onAddAttachment: (id: string) => void;
}

export const RequestEditComponent: FunctionComponent<IProps> = ({
  currentUser,
  metadata,
  showEditableFieldWarning,
  setInitialValues,
  attachmentIds,
  onAddAttachment,
  ...props
}) => {
  const C = useStyles();
  const G = useGeneralStyles();

  return (
    <Form noValidate>
      <Grid container justify={'space-around'}>
        <Grid item xs={8}>
          <DraftPanelContainer />
          <Card className={C.card}>
            <CardHeader title={I18n.t('Requests.Create.Title')} className={C.cardHeader} />
            <CardContent>
              <Visible visible={showEditableFieldWarning}>
                <Card variant="outlined" className={G.warningRed}>
                  <div className={C.warningHeader}>
                    <Typography style={{ fontWeight: 400, fontSize: '1.2em' }}>
                      <InfoIcon className={C.icon} /> {I18n.t('Requests.Create.EditingLimited.Title')}
                    </Typography>
                  </div>
                  {I18n.t('Requests.Create.EditingLimited.Description')}
                </Card>
              </Visible>
              <Auth acl={IRequestAclActions.createRequestForAnotherUser}>
                <Visible visible={!isUtilitiesUser(currentUser)}>
                  <SelectUserComponent />
                </Visible>
              </Auth>
              <Divider />
              <RequestFormUserInfoComponent values={props.values} setFieldValue={props.setFieldValue} />
              <Divider />
              <RequestFormAddressComponent values={props.values} />
              <Divider />
              <RequestFormReasonComponent
                key={props.values['reason']}
                values={props.values}
                setFieldValue={props.setFieldValue}
              />
              <Divider />
              <RequestFormDatetimeComponent
                errors={props.errors}
                values={props.values}
                setFieldValue={props.setFieldValue}
              />
              <br />
              <AttachmentsComponent localIds={attachmentIds} onAddFile={onAddAttachment} />
              <Divider />
              <Grid container className={C.actions} justify="flex-end">
                <Grid item>
                  <Button.Green variant="contained" color="primary" onClick={props.submitForm}>
                    {I18n.t('Requests.Create.Locations')}
                    <NavigateNext fontSize={'small'} />
                  </Button.Green>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Form>
  );
};
