import { Divider, FormHelperText, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormikErrors } from 'formik';
import I18n from 'i18n-js';
import moment from 'moment';
import { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  FormikAutocompleteField,
  FormikCheckboxField,
  FormikDateField,
} from '../../../common/components/formikFormFields';
import { PanelTitle } from '../../../common/components/panel/panel-title.component';
import { FROM_TIMES, toTimeOptions, UNTIL_TIMES } from '../../../common/utils/moment.util';
import { RequestCreateField, IRequestFormData, DateFormat } from '../../../types';
import { Visible } from '../../../common/components/layout/Visible.component';
import { useMetadata } from '../../../hooks';
import { RequestsApi } from '../../../store/api';
import { getEditableFieldsIncludes, getSettings, selectUser } from '../../../store/selectors';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';
import { AsignDatepicker } from '../../../common/components/form-fields/datepicker/asign-datepicker.component';
import { convertDateFormat } from '../../../common/utils/date.util';
import { SingleSelectV2Component } from '../../../common/components/form-fields/single-select-v2.component';
import { CancelOutlined } from '@material-ui/icons';

interface IProps {
  errors: FormikErrors<IRequestFormData>;
  values: IRequestFormData;
  setFieldValue: (field: string, value: any) => void;
}

const useStyles = makeStyles({
  container: { width: 550 },
  countryField: { width: 300 },
  infoText: {
    fontSize: 13,
    color: '#9e9e9e',
    fontStyle: 'italic',
  },
  validation: {
    paddingLeft: '16px',
  },
  timeAutoComplete: {
    marginLeft: '10px',
  },
  dateTimeInput: {
    display: 'flex',
    flexDirection: 'column',
  },
  preferredMomentContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  preferredMomentClearButton: {
    fontSize: '14px',
  },
});

const dateFormat = 'YYYY-MM-DD';

export const RequestFormDatetimeComponent: FunctionComponent<IProps> = ({ errors, values, setFieldValue }) => {
  const C = useStyles();

  const currentUser = useSelector(selectUser);
  const settings = useSelector(getSettings);
  const createRequestWithoutLimitations = currentUser?.acl.createRequestWithoutLimitations;
  const metadata = useMetadata();

  const [latestEndDate, setLatestEndDate] = useState(moment());

  useEffect(() => {
    const fetchValidEndDate = async () => {
      if (!createRequestWithoutLimitations && settings?.UTILITY_COMPANY_START_DATE_BUFFER) {
        const earliestStartDate = values.dateFrom
          ? moment(values.dateFrom)
          : moment().add(settings.UTILITY_COMPANY_START_DATE_BUFFER, 'days');

        try {
          const response = await RequestsApi.workingDaysCalculation(
            earliestStartDate.format(dateFormat),
            settings.UTILITY_COMPANY_MAX_LENGTH_OF_WORKS,
          );
          setLatestEndDate(moment(response.data.data));
        } catch (error) {
          console.error('Error fetching working days:', error);
        }
      }
    };

    fetchValidEndDate();
  }, [createRequestWithoutLimitations, settings, setFieldValue, values.dateFrom]);

  const timeFromDisabled = !useSelector(getEditableFieldsIncludes(RequestCreateField.timeFrom));
  const timeUntilDisabled = !useSelector(getEditableFieldsIncludes(RequestCreateField.timeUntil));
  const isPreferredMomentDisabled = !useSelector(getEditableFieldsIncludes(RequestCreateField.entireDay));

  if (!values.dateFrom) {
    if (createRequestWithoutLimitations) {
      setFieldValue(RequestCreateField.dateFrom, moment());
    } else if (settings.UTILITY_COMPANY_START_DATE_BUFFER) {
      setFieldValue(RequestCreateField.dateFrom, moment().add(settings.UTILITY_COMPANY_START_DATE_BUFFER, 'days'));
    }
  }

  if (!values.dateUntil) {
    if (createRequestWithoutLimitations) {
      setFieldValue(RequestCreateField.dateUntil, moment());
    } else if (settings.UTILITY_COMPANY_START_DATE_BUFFER) {
      setFieldValue(RequestCreateField.dateUntil, moment().add(settings.UTILITY_COMPANY_START_DATE_BUFFER, 'days'));
    }
  }

  if (moment(values.dateFrom, dateFormat) > moment(values.dateUntil, dateFormat)) {
    setFieldValue(RequestCreateField.dateUntil, values.dateFrom);
  }

  const startDateBuffer =
    metadata.settings.find((setting) => setting.name === 'UTILITY_COMPANY_START_DATE_BUFFER')?.value || 0;

  const dateFromMinDate = createRequestWithoutLimitations ? moment() : moment().add(startDateBuffer, 'days');

  return (
    <>
      <PanelTitle>{I18n.t('Requests.Create.DateTitle')}</PanelTitle>

      <Grid container className={C.container} spacing={4} justify={'flex-start'}>
        <Grid item xs={12} className={C.infoText}>
          {I18n.t(`Requests.Create.SelectedPeriodStartsBeforeTheNormalProcessingTime`)}
        </Grid>
        <Grid item xs={6}>
          <FormikDateField
            disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.dateFrom))}
            name={RequestCreateField.dateFrom}
            label={I18n.t(`Requests.Create.Form.dateFrom`)}
            minDate={dateFromMinDate}
            dateFormat={dateFormat}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <FormikDateField
            disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.dateUntil))}
            name={RequestCreateField.dateUntil}
            label={I18n.t(`Requests.Create.Form.dateUntil`)}
            minDate={moment(values.dateFrom || undefined)}
            maxDate={createRequestWithoutLimitations ? undefined : moment(latestEndDate)}
            dateFormat={dateFormat}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormikCheckboxField
            disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.onlyOnWeekdays))}
            name={RequestCreateField.onlyOnWeekdays}
            label={I18n.t('Requests.Create.Form.onlyOnWeekdays')}
          />
        </Grid>
      </Grid>

      <br />
      <Divider />
      <br />

      <Visible visible={values.reason !== 'container'}>
        <Grid item xs={12} className={C.infoText}>
          {I18n.t('Requests.Create.TimeInfo')}
        </Grid>

        <PanelTitle>{I18n.t('Requests.Create.TimeTitle')}</PanelTitle>
        <Grid container className={C.container} spacing={4} justify={'flex-start'}>
          {!values.entireDay && (
            <>
              <Grid item xs={6}>
                <FormikAutocompleteField
                  disabled={timeFromDisabled}
                  name={RequestCreateField.timeFrom}
                  label={I18n.t(`Requests.Create.Form.timeFrom`)}
                  options={toTimeOptions(FROM_TIMES)}
                  value={`${values.timeFrom}`}
                  error={!!errors.timeFrom}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikAutocompleteField
                  disabled={timeUntilDisabled}
                  name={RequestCreateField.timeUntil}
                  label={I18n.t(`Requests.Create.Form.timeUntil`)}
                  options={toTimeOptions(UNTIL_TIMES)}
                  value={`${values.timeUntil}`}
                  error={!!errors.timeUntil}
                />
              </Grid>
              {(errors.timeUntil || errors.timeFrom) && (
                <FormHelperText error className={C.validation}>
                  {I18n.t('Requests.Create.Validation.TimeOrder')}
                </FormHelperText>
              )}
            </>
          )}
          <Grid item xs={12}>
            <FormikCheckboxField
              disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.entireDay))}
              name={RequestCreateField.entireDay}
              label={I18n.t('Requests.Create.Form.entireDay')}
            />
          </Grid>
          {!createRequestWithoutLimitations && (
            <Grid item xs={12}>
              <LabelValue label={I18n.t(`Requests.Create.Form.preferredMoment`)}>
                <div className={C.dateTimeInput}>
                  <div className={C.preferredMomentContainer}>
                    <AsignDatepicker
                      value={moment(values.preferredMoment, DateFormat.reduxStoreDateString)}
                      onChange={(value) => {
                        const isValid = moment(value).isValid();
                        const newPreferredMoment = convertDateFormat(
                          value,
                          DateFormat.reduxStoreDateTimeString,
                          DateFormat.reduxStoreDateTimeString,
                        );
                        setFieldValue(RequestCreateField.preferredMoment, isValid ? newPreferredMoment : '');
                      }}
                      disabled={isPreferredMomentDisabled}
                      minDate={values.dateFrom ? moment(values.dateFrom) : moment()}
                      maxDate={values.dateUntil ? moment(values.dateUntil) : moment()}
                      required
                    />
                    <SingleSelectV2Component
                      fullWidth={false}
                      onChange={(e) => {
                        const [hours, minutes] = `${e?.target?.value}`.split(':').map(Number);
                        const newPreferredMoment = moment(values.preferredMoment)
                          .set({ hour: hours, minute: minutes })
                          .format(DateFormat.reduxStoreDateTimeString);
                        setFieldValue(RequestCreateField.preferredMoment, newPreferredMoment);
                      }}
                      menuItems={toTimeOptions(FROM_TIMES)}
                      className={C.timeAutoComplete}
                      value={values.preferredMoment ? moment(values.preferredMoment).format(DateFormat.time) : ''}
                      disabled={isPreferredMomentDisabled}
                    />
                    {values.preferredMoment && (
                      <IconButton
                        className={C.preferredMomentClearButton}
                        onClick={() => {
                          setFieldValue(RequestCreateField.preferredMoment, '');
                        }}
                      >
                        <CancelOutlined fontSize="inherit" />
                      </IconButton>
                    )}
                  </div>

                  {errors.preferredMoment && (
                    <FormHelperText error className={C.validation}>
                      {errors.preferredMoment}
                    </FormHelperText>
                  )}
                </div>
              </LabelValue>
            </Grid>
          )}
        </Grid>
      </Visible>
    </>
  );
};
