import { FormHelperText, Grid, makeStyles, MenuItem, Paper, Select } from '@material-ui/core';
import I18n from 'i18n-js';
import * as React from 'react';
import { CSSProperties, FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Feedback } from '../../../common/components/layout/Feedback.component';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import { PanelContent } from '../../../common/components/panel/panel-content.component';
import { getRequestAssignedUser, getRequestsDetails } from '../../../store/selectors';
import { IRequestAclActions, IRequestDetailComponent, RequestStates } from '../../../types';
import { PermitButtons } from './permit-buttons.component';
import { useAuthorization, useRequestMetadata } from '../../../hooks';
import { PriorityPicker } from '../../../components/atoms/PriorityPicker/PriorityPicker.component';
import { Priority } from '../../../types/priority.types';
import { appUrls } from '../../../common/config/url.constants';
import moment from 'moment';

const useStyles = makeStyles({
  pincodeContainer: { display: 'flex' },
  pincodeFeedback: { margin: 0, marginLeft: 10 },
});

const styles = {
  selectCluster: { width: '200px' },
  comment: {
    whiteSpace: 'pre-wrap',
    maxHeight: 300,
    overflowY: 'auto',
    overflowX: 'hidden',
  } as CSSProperties,
};

interface IProps extends IRequestDetailComponent {
  patchPriority: (priority: number) => void;
  patchCluster: (cluster: string) => void;
}

export const Info: FunctionComponent<IProps> = ({ patchPriority, patchCluster, requestId, visible = true }) => {
  const C = useStyles();
  const metadata = useRequestMetadata();
  const request = useSelector(getRequestsDetails(requestId));
  const { assignedToMe } = useSelector(getRequestAssignedUser(requestId));
  const { isAuthorized: canEditAssignedRequest } = useAuthorization(IRequestAclActions.editAssignedRequest);
  const isReadOnly = !canEditAssignedRequest;

  const [cluster, setCluster] = useState<string | undefined>(
    request?.cluster && !Array.isArray(request.cluster) ? request.cluster.cluster : undefined,
  );

  const _patchPriority = (priority: Priority) => {
    if (!isReadOnly && request && priority !== request.priority && canEditAssignedRequest) {
      patchPriority(priority);
    }
  };

  const _patchCluster = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (!isReadOnly && canEditAssignedRequest) {
      setCluster(event.target.value as string);
      patchCluster(event.target.value as string);
    }
  };

  const onlyWeekDaysTranslation = request?.onlyWeekdays ? ` ${I18n.t('Requests.Detail.Info.OnlyWeekdays')}` : '';

  return request && visible ? (
    <Paper square style={{ marginBottom: 20 }} data-testid="requestInfo">
      <PanelContent title={I18n.t('Requests.Detail.Info.Title')}>
        <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
          <LabelValue label={I18n.t('Requests.Detail.Info.IdentificationNumber')}>{request.id}</LabelValue>
          <LabelValue label={I18n.t('Requests.Detail.Info.Period')}>
            {request.period}
            <Visible visible={request.entireDay}>{onlyWeekDaysTranslation}</Visible>
          </LabelValue>
          <LabelValue
            visible={!!request.preferredMoment || !!request.withUtilityCompanyLimitations}
            label={I18n.t('Requests.Detail.Info.PreferredMoment')}
          >
            {request.preferredMoment ? moment(request.preferredMoment).format('DD-MM-YYYY HH:mm') : ''}
          </LabelValue>
          <LabelValue visible={!request.entireDay} label={I18n.t('Requests.Detail.Info.Time')}>
            {request.timeBetween}
            {onlyWeekDaysTranslation}
          </LabelValue>
          <LabelValue label={I18n.t('Requests.Detail.Info.Address')} visible={!!request.requestAddress}>
            {request.requestAddress?.street} {request.requestAddress?.streetNumber}
            {request.requestAddress?.city ? `, ${request.requestAddress?.city}` : ''}
          </LabelValue>
          <LabelValue label={I18n.t('Requests.Detail.Info.CreatedBy')} visible={!!request.createdByUrl}>
            <a href={request.createdByUrl!} target="_blank" rel="noreferrer">
              {request.createdByName}
            </a>
          </LabelValue>
          <LabelValue label={I18n.t('Requests.Detail.Info.Reason')}>{request.reason}</LabelValue>
          <Visible visible={!!request.eventName}>
            <LabelValue label={I18n.t('Requests.Detail.Info.EventName')}>{request.eventName}</LabelValue>
          </Visible>
          <LabelValue label={I18n.t('Requests.Detail.Info.DescriptionByRequester')} visible={!!request.comment}>
            <div style={styles.comment}>{request.comment}</div>
          </LabelValue>
          <LabelValue label={I18n.t('Requests.Detail.Info.ReasonForRejection')} visible={!!request.reasonForRejection}>
            {request.reasonForRejection}
          </LabelValue>
          <LabelValue label={I18n.t('Requests.Detail.Info.ReasonForApproval')} visible={!!request.reasonForApproval}>
            {request.reasonForApproval}
          </LabelValue>
          <LabelValue label={I18n.t('Requests.Detail.Info.ReasonForRejection')} visible={!!request.reasonForEdit}>
            {request.reasonForEdit}
          </LabelValue>
          <LabelValue label={I18n.t('Requests.Detail.Info.ReferenceNumber')}>{request.referenceId}</LabelValue>
          <Visible visible={!!request.sgwRequestId}>
            <LabelValue label={I18n.t('Requests.Detail.Info.SgwDossier')}>
              <a href={appUrls.sgw.requests.detail(request.sgwRequestId)}>{request.sgwRequestId}</a>
            </LabelValue>
          </Visible>
          <LabelValue label={I18n.t('Requests.Detail.Info.NumberOfParkingSpots')}>
            {request.numberOfParkingSpots}
          </LabelValue>
          <PriorityPicker
            priority={request.priority}
            onSelectPriority={_patchPriority}
            disabled={
              !assignedToMe ||
              !canEditAssignedRequest ||
              !!request.sgwRequestId ||
              request.state.state === RequestStates.rejected
            }
          />
          <LabelValue label={I18n.t('Requests.Detail.Info.YearLicenseNumber')} visible={!!request.externalReference}>
            {request.externalReference}
          </LabelValue>
          <LabelValue
            label={I18n.t('Requests.Detail.Info.ManualPlacement')}
            visible={!request.needsPlacement && !!request.manualPlacementUrl}
          >
            <a href={request.manualPlacementUrl} target="_blank" rel="noreferrer">
              {I18n.t('Requests.Detail.Info.InputPage')}
            </a>
          </LabelValue>
          <LabelValue label={I18n.t('Requests.Detail.Info.numberOfRequestedSigns')} visible={request.needsPlacement}>
            {request.numberOfRequestedSigns}
          </LabelValue>
          <LabelValue label={I18n.t('Requests.Detail.Info.numberOfPlacedSigns')} visible={request.needsPlacement}>
            {request.numberOfPlacedSigns}
          </LabelValue>
          <LabelValue label={I18n.t('Requests.Detail.Info.Cluster')} visible={request.clusterLabelVisible}>
            {request.cluster ? request.cluster.name : I18n.t('Requests.Detail.Info.NoCluster')}
          </LabelValue>
          <LabelValue
            label={I18n.t('Requests.Detail.Info.Cluster')}
            visible={!isReadOnly && request.clusterSelectVisible}
          >
            <FormHelperText>{I18n.t('Requests.Detail.Info.ChooseCluster')}</FormHelperText>
            <Select id="demo-simple-select" onChange={_patchCluster} style={styles.selectCluster} value={cluster}>
              {metadata?.clusters?.map((item) => (
                <MenuItem key={item.cluster} value={item.cluster}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </LabelValue>
          <LabelValue
            label={I18n.t('Requests.Detail.Info.Pincode')}
            visible={!!request.acl.view_pincode && !!request.pincode}
          >
            <div className={C.pincodeContainer}>
              {request?.pincode}
              <Feedback.Text className={C.pincodeFeedback}>
                {I18n.t('Requests.Detail.Info.PincodeClarification')}
              </Feedback.Text>
            </div>
          </LabelValue>
        </Grid>
      </PanelContent>
      <PermitButtons canSendEmail={request.canSendEmail} permit={request.permit} requestId={request.id} />
    </Paper>
  ) : null;
};
